.main{
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;

    position: relative;
}

.header{
    text-align: center;
    margin-bottom: 50px;
}

.button{
    background-color: #4CAF50;
    width: 250px;
    height: 40px;
    margin-left: 80px;
}

.left{
    padding-left: 20px;
}